<template>
    <BT-Blade-Item
        bladeName="client-billable-item"
        :bladesData="bladesData"
        navigation="client-billable-items"
        title="Client's Billable Item">
        <template slot-scope="{ item }">
            <v-text-field
                disabled
                label="Client"
                v-model="item.buyer.companyName"
                prepend-icon="mdi-map-marker-distance" />

            <BT-Date-Picker
                disabled
                v-model="item.dateOfItem"
                label="Date" />

            <v-text-field
                disabled
                label="Distance (Km)"
                v-model.number="item.distance"
                type="number"
                prepend-icon="mdi-map-marker-distance" />
            
            <label>Zone</label>
            <BT-Entity                
                navigation="zones"
                :itemValue="item.zoneID"
                itemText="zoneName" />

            <v-list>
                <v-subheader>Packages</v-subheader>
                <v-list-item v-for="(packaged, index) in item.billablePackagedItems" :key="index">
                    <v-list-item-content>
                        <v-list-item-title>
                            <BT-Entity
                                navigation="measurements"
                                :itemValue="packaged.measurementID"
                                itemText="measurementName">
                                <template slot="alternate">
                                    {{ packaged.measurementStandard }}
                                </template>
                            </BT-Entity>
                        </v-list-item-title>
                        <v-list-item-subtitle>{{ packaged | toMeasurementLine }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                        {{ packaged.quantity | toDisplayNumber }}
                    </v-list-item-action>
                </v-list-item>
            </v-list>

        </template>
    </BT-Blade-Item>
</template>

<script>
export default {
    name: 'Client-Billable-Item',
    props: {
        bladesData: null
    }
}
</script>